// WARNING: DO NOT EDIT.  This file is automatically generated
// Written by aws-amplify-serverless-plugin/1.2.0 on 2018-12-16T00:23:16.205Z

const awsmobile = {
  "aws_project_region": "ap-southeast-1",
  "aws_cognito_region": "ap-southeast-1",
  "aws_user_pools_id": "ap-southeast-1_OchsQpf8q",
  "aws_user_pools_web_client_id": "g0ddhgd2i56aor2caimanq2n4",
  "aws_cognito_identity_pool_id": "ap-southeast-1:92bb96e3-99a1-46db-b7ac-4e440a68f672",
  "aws_appsync_graphqlEndpoint": "https://kc4jbw3awfhtdc5ryhrr3pbybi.appsync-api.ap-southeast-1.amazonaws.com/graphql",
  "aws_appsync_region": "ap-southeast-1",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};

export default awsmobile;
