// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import Auth from '@aws-amplify/auth';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import awsconfig from './aws-exports';

Auth.configure(awsconfig);

const element = document.getElementById('root')
if (element) ReactDOM.render(<App />, element);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
